export const ProjectLogo = `
    <path d="M184.841 36.2344H171.657C171.551 36.2344 171.498 36.1689 171.498 36.038L171.538 8.41657C171.538 8.31187 171.591 8.25952 171.697 8.25952H184.801C184.908 8.25952 184.961 8.32496 184.961 8.45584V12.9514C184.961 13.0561 184.908 13.1085 184.801 13.1085H176.437V19.3906H184.801C184.908 19.3906 184.961 19.4429 184.961 19.5476L185 24.1021C185 24.2068 184.947 24.2592 184.841 24.2592H176.437V31.2872H184.841C184.947 31.2872 185 31.3527 185 31.4835V36.0773C185 36.182 184.947 36.2344 184.841 36.2344Z" fill="#1C1C1C"/>
    <path d="M156.602 36.2344H151.982C151.875 36.2344 151.822 36.1689 151.822 36.038L151.902 8.41657C151.902 8.31187 151.955 8.25952 152.061 8.25952H160.306C161.78 8.25952 163.134 8.61289 164.369 9.31962C165.603 10.0264 166.586 10.9752 167.316 12.1662C168.06 13.3572 168.431 14.7052 168.431 16.2103C168.431 17.1918 168.279 18.0818 167.973 18.8801C167.668 19.6785 167.31 20.3525 166.898 20.9022C166.5 21.4519 166.135 21.8576 165.803 22.1193C167.276 23.7291 168.013 25.6203 168.013 27.7928L168.053 36.038C168.053 36.1689 167.987 36.2344 167.854 36.2344H163.234C163.127 36.2344 163.074 36.1951 163.074 36.1166V27.7928C163.074 26.8243 162.729 25.9867 162.039 25.28C161.348 24.5733 160.499 24.2199 159.49 24.2199H156.801L156.761 36.038C156.761 36.1689 156.708 36.2344 156.602 36.2344ZM160.306 13.0692H156.801V19.3906H160.306C161.142 19.3906 161.886 19.083 162.537 18.4679C163.2 17.8528 163.532 17.1002 163.532 16.2103C163.532 15.3596 163.214 14.6267 162.576 14.0115C161.939 13.3833 161.182 13.0692 160.306 13.0692Z" fill="#1C1C1C"/>
    <path d="M148.077 36.2344H143.457C143.324 36.2344 143.258 36.1689 143.258 36.038L143.298 8.41657C143.298 8.31187 143.351 8.25952 143.457 8.25952H148.037C148.144 8.25952 148.197 8.31187 148.197 8.41657L148.237 36.038C148.237 36.1689 148.183 36.2344 148.077 36.2344Z" fill="#1C1C1C"/>
    <path d="M128.68 36.2344H124.06C123.953 36.2344 123.9 36.1689 123.9 36.038L123.94 8.41657C123.94 8.31187 124.007 8.25952 124.139 8.25952H128.68C128.813 8.25952 128.879 8.31187 128.879 8.41657L128.839 19.3513H134.953V8.41657C134.953 8.31187 135.006 8.25952 135.113 8.25952H139.653C139.786 8.25952 139.852 8.31187 139.852 8.41657L139.932 36.038C139.932 36.1689 139.866 36.2344 139.733 36.2344H135.152C135.02 36.2344 134.953 36.1689 134.953 36.038V24.2199H128.839V36.038C128.839 36.1689 128.786 36.2344 128.68 36.2344Z" fill="#1C1C1C"/>
    <path d="M116.273 36.2344H111.673C111.553 36.2344 111.494 36.1689 111.494 36.038V13.1085H106.196C106.063 13.1085 105.997 13.0431 105.997 12.9122L106.037 8.41657C106.037 8.31187 106.09 8.25952 106.196 8.25952H121.69C121.836 8.25952 121.909 8.31187 121.909 8.41657V12.9122C121.909 13.0431 121.856 13.1085 121.75 13.1085H116.393L116.433 36.038C116.433 36.1689 116.379 36.2344 116.273 36.2344Z" fill="#1C1C1C"/>
    <path d="M96.7369 36.627C95.2897 36.627 93.9554 36.2606 92.734 35.5277C91.5258 34.7947 90.5566 33.8197 89.8264 32.6026C89.1094 31.3723 88.751 30.0243 88.751 28.5585V26.7131C88.751 26.5692 88.8174 26.4972 88.9501 26.4972H93.5306C93.6368 26.4972 93.6899 26.5692 93.6899 26.7131V28.5585C93.6899 29.4354 93.9886 30.1944 94.5861 30.8357C95.1835 31.4639 95.9005 31.778 96.7369 31.778C97.5866 31.778 98.3102 31.4574 98.9076 30.8161C99.5051 30.1617 99.8038 29.4092 99.8038 28.5585C99.8038 27.5769 99.1533 26.7197 97.8521 25.9868C97.6397 25.8559 97.3609 25.6988 97.0157 25.5156C96.6838 25.3193 96.2855 25.0968 95.8208 24.8481C95.3561 24.5995 94.9047 24.3574 94.4666 24.1218C94.0285 23.8731 93.6036 23.6375 93.192 23.415C91.705 22.5513 90.5964 21.4715 89.8662 20.1758C89.1493 18.8671 88.7908 17.4013 88.7908 15.7784C88.7908 14.2864 89.1626 12.9384 89.906 11.7343C90.6495 10.5433 91.6187 9.60104 92.8136 8.90739C94.0218 8.20066 95.3296 7.84729 96.7369 7.84729C98.1841 7.84729 99.5117 8.20066 100.72 8.90739C101.928 9.62721 102.891 10.5826 103.608 11.7736C104.338 12.9646 104.703 14.2995 104.703 15.7784V19.0765C104.703 19.1812 104.65 19.2335 104.544 19.2335H99.9631C99.8569 19.2335 99.8038 19.1812 99.8038 19.0765L99.764 15.7784C99.764 14.8361 99.4653 14.0705 98.8678 13.4815C98.2704 12.8926 97.5601 12.5981 96.7369 12.5981C95.9005 12.5981 95.1835 12.9122 94.5861 13.5404C93.9886 14.1686 93.6899 14.9146 93.6899 15.7784C93.6899 16.6553 93.8758 17.3882 94.2475 17.9771C94.6326 18.5661 95.3296 19.1288 96.3386 19.6654C96.4448 19.7178 96.6904 19.8487 97.0755 20.0581C97.4605 20.2675 97.8853 20.503 98.35 20.7648C98.828 21.0135 99.2595 21.2425 99.6445 21.4519C100.03 21.6482 100.262 21.766 100.342 21.8053C101.696 22.5513 102.765 23.4674 103.548 24.5537C104.344 25.6399 104.743 26.9749 104.743 28.5585C104.743 30.0897 104.384 31.4639 103.667 32.6811C102.937 33.8982 101.968 34.8602 100.76 35.5669C99.5516 36.2736 98.2106 36.627 96.7369 36.627Z" fill="#1C1C1C"/>
    <path d="M78.5543 36.627C77.1204 36.627 75.7927 36.2605 74.5713 35.5276C73.3498 34.7947 72.374 33.8131 71.6438 32.5829C70.9268 31.3396 70.5684 29.985 70.5684 28.5192L70.648 8.41657C70.648 8.31187 70.7011 8.25952 70.8073 8.25952H75.3878C75.494 8.25952 75.5471 8.31187 75.5471 8.41657V28.5192C75.5471 29.4222 75.8392 30.1944 76.4234 30.8357C77.0076 31.4639 77.7179 31.778 78.5543 31.778C79.4306 31.778 80.1608 31.4639 80.7449 30.8357C81.3291 30.1944 81.6212 29.4222 81.6212 28.5192V8.41657C81.6212 8.31187 81.6743 8.25952 81.7805 8.25952H86.361C86.4672 8.25952 86.5203 8.31187 86.5203 8.41657L86.6 28.5192C86.6 29.9981 86.2415 31.3592 85.5246 32.6025C84.7943 33.8328 83.8251 34.8143 82.617 35.5473C81.4088 36.2671 80.0546 36.627 78.5543 36.627Z" fill="#1C1C1C"/>
    <path d="M60.0133 36.6269C58.513 36.6269 57.1521 36.2735 55.9307 35.5668C54.7225 34.86 53.7666 33.885 53.0629 32.6417C52.3725 31.3853 52.0273 29.9849 52.0273 28.4406V26.5756C52.0273 26.4709 52.0805 26.4185 52.1867 26.4185H56.807C56.9132 26.4185 56.9663 26.4905 56.9663 26.6345V28.4406C56.9663 29.4221 57.2517 30.227 57.8226 30.8552C58.3935 31.4703 59.1237 31.7779 60.0133 31.7779C60.8364 31.7779 61.5467 31.4442 62.1442 30.7767C62.7416 30.0961 63.0404 29.3174 63.0404 28.4406V8.45573C63.0404 8.35103 63.0935 8.29868 63.1997 8.29868H67.7801C67.8864 8.29868 67.9395 8.36411 67.9395 8.49499L67.9793 28.4406C67.9793 29.9064 67.6208 31.2609 66.9039 32.5043C66.1737 33.7607 65.2111 34.7619 64.0162 35.5079C62.8213 36.2539 61.487 36.6269 60.0133 36.6269Z" fill="#1C1C1C"/>
    <path d="M22.3178 0C14.0274 0 6.79276 4.45604 2.94426 11.071L13.6642 22.5336L32.5408 2.43861C29.478 0.880078 26.0022 0 22.3178 0Z" fill="#28C700"/>
    <path d="M22.3178 44C34.6436 44 44.6356 34.1503 44.6356 22C44.6356 16.6574 42.7037 11.7596 39.4909 7.94839L13.6642 33.4627L0.0553325 20.4387C0.01865 20.9544 0 21.4751 0 22C0 34.1503 9.99201 44 22.3178 44Z" fill="#5F14E1"/>
   `;

export const WhiteLogo = `
<path d="M184.841 36.2344H171.657C171.551 36.2344 171.498 36.1689 171.498 36.038L171.538 8.41657C171.538 8.31187 171.591 8.25952 171.697 8.25952H184.801C184.908 8.25952 184.961 8.32496 184.961 8.45584V12.9514C184.961 13.0561 184.908 13.1085 184.801 13.1085H176.437V19.3906H184.801C184.908 19.3906 184.961 19.4429 184.961 19.5476L185 24.1021C185 24.2068 184.947 24.2592 184.841 24.2592H176.437V31.2872H184.841C184.947 31.2872 185 31.3527 185 31.4835V36.0773C185 36.182 184.947 36.2344 184.841 36.2344Z" fill="#FFFFFF"/>
<path d="M156.602 36.2344H151.982C151.875 36.2344 151.822 36.1689 151.822 36.038L151.902 8.41657C151.902 8.31187 151.955 8.25952 152.061 8.25952H160.306C161.78 8.25952 163.134 8.61289 164.369 9.31962C165.603 10.0264 166.586 10.9752 167.316 12.1662C168.06 13.3572 168.431 14.7052 168.431 16.2103C168.431 17.1918 168.279 18.0818 167.973 18.8801C167.668 19.6785 167.31 20.3525 166.898 20.9022C166.5 21.4519 166.135 21.8576 165.803 22.1193C167.276 23.7291 168.013 25.6203 168.013 27.7928L168.053 36.038C168.053 36.1689 167.987 36.2344 167.854 36.2344H163.234C163.127 36.2344 163.074 36.1951 163.074 36.1166V27.7928C163.074 26.8243 162.729 25.9867 162.039 25.28C161.348 24.5733 160.499 24.2199 159.49 24.2199H156.801L156.761 36.038C156.761 36.1689 156.708 36.2344 156.602 36.2344ZM160.306 13.0692H156.801V19.3906H160.306C161.142 19.3906 161.886 19.083 162.537 18.4679C163.2 17.8528 163.532 17.1002 163.532 16.2103C163.532 15.3596 163.214 14.6267 162.576 14.0115C161.939 13.3833 161.182 13.0692 160.306 13.0692Z" fill="#FFFFFF"/>
<path d="M148.077 36.2344H143.457C143.324 36.2344 143.258 36.1689 143.258 36.038L143.298 8.41657C143.298 8.31187 143.351 8.25952 143.457 8.25952H148.037C148.144 8.25952 148.197 8.31187 148.197 8.41657L148.237 36.038C148.237 36.1689 148.183 36.2344 148.077 36.2344Z" fill="#FFFFFF"/>
<path d="M128.68 36.2344H124.06C123.953 36.2344 123.9 36.1689 123.9 36.038L123.94 8.41657C123.94 8.31187 124.007 8.25952 124.139 8.25952H128.68C128.813 8.25952 128.879 8.31187 128.879 8.41657L128.839 19.3513H134.953V8.41657C134.953 8.31187 135.006 8.25952 135.113 8.25952H139.653C139.786 8.25952 139.852 8.31187 139.852 8.41657L139.932 36.038C139.932 36.1689 139.866 36.2344 139.733 36.2344H135.152C135.02 36.2344 134.953 36.1689 134.953 36.038V24.2199H128.839V36.038C128.839 36.1689 128.786 36.2344 128.68 36.2344Z" fill="#FFFFFF"/>
<path d="M116.273 36.2344H111.673C111.553 36.2344 111.494 36.1689 111.494 36.038V13.1085H106.196C106.063 13.1085 105.997 13.0431 105.997 12.9122L106.037 8.41657C106.037 8.31187 106.09 8.25952 106.196 8.25952H121.69C121.836 8.25952 121.909 8.31187 121.909 8.41657V12.9122C121.909 13.0431 121.856 13.1085 121.75 13.1085H116.393L116.433 36.038C116.433 36.1689 116.379 36.2344 116.273 36.2344Z" fill="#FFFFFF"/>
<path d="M96.7369 36.627C95.2897 36.627 93.9554 36.2606 92.734 35.5277C91.5258 34.7947 90.5566 33.8197 89.8264 32.6026C89.1094 31.3723 88.751 30.0243 88.751 28.5585V26.7131C88.751 26.5692 88.8174 26.4972 88.9501 26.4972H93.5306C93.6368 26.4972 93.6899 26.5692 93.6899 26.7131V28.5585C93.6899 29.4354 93.9886 30.1944 94.5861 30.8357C95.1835 31.4639 95.9005 31.778 96.7369 31.778C97.5866 31.778 98.3102 31.4574 98.9076 30.8161C99.5051 30.1617 99.8038 29.4092 99.8038 28.5585C99.8038 27.5769 99.1533 26.7197 97.8521 25.9868C97.6397 25.8559 97.3609 25.6988 97.0157 25.5156C96.6838 25.3193 96.2855 25.0968 95.8208 24.8481C95.3561 24.5995 94.9047 24.3574 94.4666 24.1218C94.0285 23.8731 93.6036 23.6375 93.192 23.415C91.705 22.5513 90.5964 21.4715 89.8662 20.1758C89.1493 18.8671 88.7908 17.4013 88.7908 15.7784C88.7908 14.2864 89.1626 12.9384 89.906 11.7343C90.6495 10.5433 91.6187 9.60104 92.8136 8.90739C94.0218 8.20066 95.3296 7.84729 96.7369 7.84729C98.1841 7.84729 99.5117 8.20066 100.72 8.90739C101.928 9.62721 102.891 10.5826 103.608 11.7736C104.338 12.9646 104.703 14.2995 104.703 15.7784V19.0765C104.703 19.1812 104.65 19.2335 104.544 19.2335H99.9631C99.8569 19.2335 99.8038 19.1812 99.8038 19.0765L99.764 15.7784C99.764 14.8361 99.4653 14.0705 98.8678 13.4815C98.2704 12.8926 97.5601 12.5981 96.7369 12.5981C95.9005 12.5981 95.1835 12.9122 94.5861 13.5404C93.9886 14.1686 93.6899 14.9146 93.6899 15.7784C93.6899 16.6553 93.8758 17.3882 94.2475 17.9771C94.6326 18.5661 95.3296 19.1288 96.3386 19.6654C96.4448 19.7178 96.6904 19.8487 97.0755 20.0581C97.4605 20.2675 97.8853 20.503 98.35 20.7648C98.828 21.0135 99.2595 21.2425 99.6445 21.4519C100.03 21.6482 100.262 21.766 100.342 21.8053C101.696 22.5513 102.765 23.4674 103.548 24.5537C104.344 25.6399 104.743 26.9749 104.743 28.5585C104.743 30.0897 104.384 31.4639 103.667 32.6811C102.937 33.8982 101.968 34.8602 100.76 35.5669C99.5516 36.2736 98.2106 36.627 96.7369 36.627Z" fill="#FFFFFF"/>
<path d="M78.5543 36.627C77.1204 36.627 75.7927 36.2605 74.5713 35.5276C73.3498 34.7947 72.374 33.8131 71.6438 32.5829C70.9268 31.3396 70.5684 29.985 70.5684 28.5192L70.648 8.41657C70.648 8.31187 70.7011 8.25952 70.8073 8.25952H75.3878C75.494 8.25952 75.5471 8.31187 75.5471 8.41657V28.5192C75.5471 29.4222 75.8392 30.1944 76.4234 30.8357C77.0076 31.4639 77.7179 31.778 78.5543 31.778C79.4306 31.778 80.1608 31.4639 80.7449 30.8357C81.3291 30.1944 81.6212 29.4222 81.6212 28.5192V8.41657C81.6212 8.31187 81.6743 8.25952 81.7805 8.25952H86.361C86.4672 8.25952 86.5203 8.31187 86.5203 8.41657L86.6 28.5192C86.6 29.9981 86.2415 31.3592 85.5246 32.6025C84.7943 33.8328 83.8251 34.8143 82.617 35.5473C81.4088 36.2671 80.0546 36.627 78.5543 36.627Z" fill="#FFFFFF"/>
<path d="M60.0133 36.6269C58.513 36.6269 57.1521 36.2735 55.9307 35.5668C54.7225 34.86 53.7666 33.885 53.0629 32.6417C52.3725 31.3853 52.0273 29.9849 52.0273 28.4406V26.5756C52.0273 26.4709 52.0805 26.4185 52.1867 26.4185H56.807C56.9132 26.4185 56.9663 26.4905 56.9663 26.6345V28.4406C56.9663 29.4221 57.2517 30.227 57.8226 30.8552C58.3935 31.4703 59.1237 31.7779 60.0133 31.7779C60.8364 31.7779 61.5467 31.4442 62.1442 30.7767C62.7416 30.0961 63.0404 29.3174 63.0404 28.4406V8.45573C63.0404 8.35103 63.0935 8.29868 63.1997 8.29868H67.7801C67.8864 8.29868 67.9395 8.36411 67.9395 8.49499L67.9793 28.4406C67.9793 29.9064 67.6208 31.2609 66.9039 32.5043C66.1737 33.7607 65.2111 34.7619 64.0162 35.5079C62.8213 36.2539 61.487 36.6269 60.0133 36.6269Z" fill="#FFFFFF"/>
<path d="M22.3178 0C14.0274 0 6.79276 4.45604 2.94426 11.071L13.6642 22.5336L32.5408 2.43861C29.478 0.880078 26.0022 0 22.3178 0Z" fill="#FFFFFF"/>
<path d="M22.3178 44C34.6436 44 44.6356 34.1503 44.6356 22C44.6356 16.6574 42.7037 11.7596 39.4909 7.94839L13.6642 33.4627L0.0553325 20.4387C0.01865 20.9544 0 21.4751 0 22C0 34.1503 9.99201 44 22.3178 44Z" fill="#FFFFFF"/>
    `;

export const BlueCircle = `
<svg width="100%" height="100%" viewBox="0 0 375 762" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.1" filter="url(#filter0_f_11_5475)">
<circle cx="523.5" cy="261.5" r="311.5" fill="#5F14E1"/>
</g>
<defs>
<filter id="filter0_f_11_5475" x="-388" y="-650" width="1823" height="1823" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="300" result="effect1_foregroundBlur_11_5475"/>
</filter>
</defs>
</svg> 
`;
